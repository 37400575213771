function planSelect() {
    const eleContainer = document.querySelector(".hero-home__grid");
    const ele = document.querySelectorAll(".card-plan");

    if (eleContainer) {
        eleContainer.addEventListener("mouseenter", function() {
            if (!eleContainer.classList.contains("is-hovered")) {
                eleContainer.classList.add("is-hovered");
            }
        });
        eleContainer.addEventListener("mouseleave", function() {
            if (eleContainer.classList.contains("is-hovered")) {
                eleContainer.classList.remove("is-hovered");
            }
            setTimeout(function() {}, 150);
        });

        ele.forEach(function(item) {
            item.addEventListener("mouseenter", function() {
                if (!item.parentNode.classList.contains("is-active")) {
                    item.parentNode.classList.add("is-active");
                }
            });
            item.addEventListener("mouseleave", function() {
                if (item.parentNode.classList.contains("is-active")) {
                    item.parentNode.classList.remove("is-active");
                }
                setTimeout(function() {}, 150);
            });
        });
    }
}

export default planSelect;
