function restoreWidgetMessageOnClick() {
    const field = document.querySelector("#widget-message");
    const button = document.querySelector("#widget-message-reset");

    if (field && button) {
        button.addEventListener("click", () => {
            field.value = button.dataset.reset;
            field.dispatchEvent(new Event("change"));
        });
    }
}

export default restoreWidgetMessageOnClick;
