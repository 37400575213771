/* global */

const enableSubmit = () => {
    if (document.querySelector(".form-required")) {
        var form = document.querySelector(".form-required");
        var required = document.querySelectorAll("input.required");
        var submit = document.querySelector('button[type="submit"]');

        form.addEventListener("keyup", function() {

            var disabled = false;

            required.forEach(input => {
                if (
                    input.value === "" ||
                    !input.value.replace(/\s/g, "").length
                ) {
                    disabled = true;
                }
            });

            if (disabled) {
                submit.classList.add("disabled");
            } else {
                submit.classList.remove("disabled");
            }
        });
    }
};

export default enableSubmit;
