function flattenObject(obj, parentKey = "") {
    let result = {};

    for (let key in obj) {
        if (Object.hasOwn(obj, key)) {
            let newKey = parentKey ? `${parentKey}[${key}]` : key;

            if (typeof obj[key] === "object" && obj[key] !== null) {
                // Recursively flatten nested objects
                Object.assign(result, flattenObject(obj[key], newKey));
            } else {
                result[newKey] = obj[key];
            }
        }
    }

    return result;
}

function setInputValue(element, value) {
    if (element.type === "radio") {
        if (element.value === value) {
            element.checked = true;
            element.dispatchEvent(new Event("change"));
        }

        return;
    }

    if (element.value === value) {
        return;
    }

    element.value = value;

    element.dispatchEvent(new Event("input"));
    element.dispatchEvent(new Event("change"));
}

export default () => {
    const forms = document.querySelectorAll("form[data-default]");

    forms.forEach(form => {
        const defaults = flattenObject(JSON.parse(form.dataset["default"]));

        form.querySelectorAll("[data-reset-tab]").forEach(button => {
            const tab = form.querySelector(
                `[data-widget-tab="${button.dataset.resetTab}"]`
            );

            button.addEventListener("click", event => {
                event.preventDefault();

                let parent = tab.querySelector("[data-active-language]") || tab;

                const inputs = parent.querySelectorAll([
                    "input",
                    "select",
                    "textarea",
                ]);
                inputs.forEach(input => {
                    if (defaults[input.name]) {
                        setInputValue(input, defaults[input.name]);
                    }
                });
            });
        });
        // form.addEventListener("reset", event => {
        //     event.preventDefault();
        //
        //     const inputs = form.querySelectorAll([
        //         "input",
        //         "select",
        //         "textarea",
        //     ]);
        //
        //     inputs.forEach(input => {
        //         if (defaults[input.name]) {
        //             setInputValue(input, defaults[input.name]);
        //         }
        //     });
        // });
    });
};
