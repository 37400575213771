function accordions() {
    // Convert node list to an array to save having to use an additional transform plugin for babel
    const theAccordions = Array.prototype.slice.call(
        document.querySelectorAll(".accordion")
    );

    if (theAccordions.length) {
        theAccordions.forEach(accordion => {
            const head = accordion.querySelector(".accordion__head");
            const panel = accordion.querySelector(".accordion__panel");
            const pinner = accordion.querySelector(".accordion__panel__inner");

            const accordionIsActive = () =>
                accordion.classList.contains("is-active");

            // remove inline styles after the transition
            pinner.setAttribute("hidden", "hidden");
            panel.addEventListener("transitionend", () => (panel.style = ""));

            const openPanel = () => {
                panel.style.maxHeight = "0px";
                panel.style.overflow = "hidden";
                void panel.offsetHeight;
                panel.style.transition =
                    "max-height 0.5s cubic-bezier(0.2, 0, 0, 1)";
                panel.style.maxHeight = panel.scrollHeight + "px";
                accordion.classList.add("is-active");
                head.setAttribute("aria-expanded", true);
            };

            const closePanel = () => {
                panel.style.maxHeight = panel.scrollHeight + "px";
                panel.style.transition =
                    "max-height 0.5s cubic-bezier(0.2, 0, 0, 1)";
                panel.style.overflow = "hidden";
                void panel.offsetHeight;
                panel.style.maxHeight = "0px";
                accordion.classList.remove("is-active");
                head.setAttribute("aria-expanded", false);
            };

            head.addEventListener("click", () => {
                if (!accordionIsActive()) {
                    pinner.removeAttribute("hidden");
                    openPanel();
                } else {
                    closePanel();
                    setTimeout(function() {
                        pinner.setAttribute("hidden", "hidden");
                    }, 500);
                }
            });
        });
    }
}

export default accordions;
