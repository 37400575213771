class PaymentPlanToggle {
    constructor(
        selector,
        config = {
            arguments: {},
            events: {},
        }
    ) {
        this.selector = selector;
        this.config = config;
        this.elements = document.querySelectorAll(this.selector);

        this.arguments = this.config.arguments;

        if (this.elements.length === 0) {
            return;
        }

        this.mount();
    }

    mount() {
        if (this.config.events) {
            Object.keys(this.config.events).forEach(eventName => {
                let eventCallback = this.config.events[eventName];

                this.elements.forEach(el =>
                    el.addEventListener(eventName, eventCallback, {
                        passive: true,
                        once: false,
                    })
                );
            });
        }

        this.init();
    }

    init() {
        this.elements.forEach(radio => {
            radio.addEventListener("change", () => {
                const els = document.querySelectorAll("[data-plan-frequency]");

                els.forEach(el => {
                    if (el.getAttribute("data-plan-frequency") == radio.value) {
                        el.style.display = null;

                        const input = el.querySelector("input");

                        if (radio.value !== "Lifetime") {
                            input.checked = true;
                        }
                    } else {
                        el.style.display = "none";
                    }
                });
            });
        });
    }
}

export default PaymentPlanToggle;
