export default () => {
    const preview = document.querySelector(".widget-preview");
    const tabButtons = document.querySelectorAll(".widget-tabs__button");
    const tabPanels = document.querySelectorAll(".widget-tab-panel");

    function openTabId(id) {
        const button = Array.from(tabButtons).find(
            button => button.dataset.tabId === id
        );
        const hidePreview = button.dataset.hidePreview !== undefined;

        tabButtons.forEach(button => {
            button.classList.toggle(
                "widget-tabs__button--active",
                button.dataset.tabId === id
            );
        });

        tabPanels.forEach(panel => {
            panel.classList.toggle("widget-tab-panel--active", panel.id === id);
        });

        preview.classList.toggle("widget-preview--hidden", hidePreview);
    }

    tabButtons.forEach(button => {
        button.addEventListener("click", () => {
            openTabId(button.dataset.tabId);
        });
    });
};
