const reviewsTicker = function() {
    const reviews = document.querySelector(".reviews");
    if (reviews) {
        const reviewsRows = reviews.querySelectorAll(".reviews__row");

        reviewsRows.forEach(row => {
            // Loop over each row of review cards
            const reviewCards = row.querySelectorAll(".card-review");

            reviewCards.forEach(card => {
                // clone each card and append it as a child to that same
                const clonedCard = card.cloneNode(true);
                row.appendChild(clonedCard);
            });
        });
    }
};

export default reviewsTicker;
