export default function widgetInstructions() {
    const googleConsentModeInputs = document.querySelectorAll(
        "[data-update-consent-mode]"
    );

    const instructionSets = document.querySelectorAll("[data-instructions]");

    googleConsentModeInputs.forEach(input =>
        input.addEventListener("change", () => {
            if (input.checked) {
                switch (input.value) {
                    case "on":
                        instructionSets.forEach(el => {
                            el.classList.toggle(
                                "hidden",
                                el.dataset.instructions !== "consent-mode"
                            );
                        });
                        break;
                    case "off":
                        instructionSets.forEach(el => {
                            el.classList.toggle(
                                "hidden",
                                el.dataset.instructions !== "default"
                            );
                        });
                        break;
                }
            }
        })
    );

    const installationLanguageDropdowns = document.querySelectorAll(
        "[data-installation-language]"
    );

    installationLanguageDropdowns.forEach(dropdown => {
        const embedCode = dropdown
            .closest("[data-instructions]")
            .querySelector("[data-embed-code]");

        let priorValue = dropdown.value;
        dropdown.addEventListener("change", () => {
            /**
             * Replace the lang code within the embed code. We identify it with a leading slash and trailing quote
             * to ensure we're only replacing the lang code within the url. There are better ways to do this, but it is late
             */
            embedCode.textContent = embedCode.textContent.replace(
                `/${priorValue}"`,
                `/${dropdown.value}"`
            );
            priorValue = dropdown.value;
        });
    });
}
