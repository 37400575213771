/* global jQuery */

class BtnExpand {
    constructor(
        selector,
        config = {
            classname: "btn--expand",
            closeOnOutsideClick: false,
            events: {},
        }
    ) {
        this.selector = selector;
        this.config = config;
        this.elements = document.querySelectorAll(this.selector);
        this.is_ie11 = !!window.MSInputMethodContext && !!document.documentMode;

        if (this.elements.length === 0) {
            return;
        }

        this.mount();
    }

    mount() {
        if (this.config.events) {
            Object.keys(this.config.events).forEach(eventName => {
                let eventCallback = this.config.events[eventName];

                this.elements.forEach(
                    el => el.addEventListener(eventName, eventCallback),
                    {
                        passive: true,
                        once: true,
                    }
                );
            });
        }

        this.init();
    }

    init() {
        this.elements.forEach(btn => {
            btn.addEventListener("click", () => {
                this.toggleBtn(
                    btn,
                    btn.getAttribute("aria-expanded") == "true" ? false : true
                );
            });

            if (this.config.closeOnOutsideClick) {
                document.addEventListener(
                    "click",
                    evt => {
                        if (
                            jQuery(evt.target).parents(this.selector).length ===
                                0 &&
                            evt.target !== btn &&
                            jQuery(evt.target).parents(
                                `#${btn.getAttribute("aria-controls")}`
                            ).length === 0
                        ) {
                            this.toggleBtn(btn, false);
                        }
                    },
                    false
                );
            }
        });
    }

    toggleBtn(btn, is_expanded) {
        const target = document.getElementById(
            btn.getAttribute("aria-controls")
        );

        btn.setAttribute("aria-expanded", is_expanded);

        if (target) {
            const parents = document.querySelectorAll(
                `[data-policy="${target.getAttribute("id")}"]`
            );

            target.setAttribute("data-expanded", is_expanded);

            parents.forEach(parent => {
                if (is_expanded) {
                    parent.classList.add("is-expanded");
                } else {
                    parent.classList.remove("is-expanded");
                }
            });
        }
    }
}

export default BtnExpand;
