const policyTabs = () => {
    const policy = document.querySelector(".policy-tabs");

    let tabs;
    let tabList;
    let policyPacks;

    if (policy != null) {
        tabs = policy.querySelectorAll('[role="tab"]');
        tabList = policy.querySelector('[role="tablist"]');
        policyPacks = policy.querySelectorAll(".policy-packs");
    }

    if (tabs) {
        // Add a click event handler to each tab
        tabs.forEach(tab => {
            tab.addEventListener("click", changeTabs);
        });
    }
    if (tabList) {
        // Enable arrow navigation between tabs in the tab list
        let tabFocus = 0;

        tabList.addEventListener("keydown", e => {
            // Move right
            if (e.keyCode === 39 || e.keyCode === 37) {
                tabs[tabFocus].setAttribute("tabindex", -1);
                if (e.keyCode === 39) {
                    tabFocus++;
                    // If we're at the end, go to the start
                    if (tabFocus >= tabs.length) {
                        tabFocus = 0;
                    }
                    // Move left
                } else if (e.keyCode === 37) {
                    tabFocus--;
                    // If we're at the start, move to the end
                    if (tabFocus < 0) {
                        tabFocus = tabs.length - 1;
                    }
                }
                tabs[tabFocus].setAttribute("tabindex", 0);
                tabs[tabFocus].focus();
            }
        });
    }

    if (policyPacks) {
        policyPacks.forEach(pack => {
            const inner = pack.getElementsByClassName("policy-packs__inner")[0];
            pack.style.setProperty("--height", inner.offsetHeight + "px");

            let timeout;
            window.addEventListener("resize", function() {
                clearTimeout(timeout);
                timeout = setTimeout(function() {
                    pack.style.setProperty(
                        "--height",
                        inner.offsetHeight + "px"
                    );
                }, 150);
            });
        });
    }

    function changeTabs(e) {
        const target = e.target;
        const parent = target.parentNode;
        const grandparent = parent.parentNode;

        // Remove all current selected tabs
        parent
            .querySelectorAll('[aria-selected="true"]')
            .forEach(t => t.setAttribute("aria-selected", false));

        // Set this tab as selected
        target.setAttribute("aria-selected", true);

        // Hide all tab panels
        grandparent
            .querySelectorAll('[role="tabpanel"]')
            .forEach(p => p.classList.remove("is-active"));

        // Show the selected panel
        grandparent.parentNode
            .querySelector(`#${target.getAttribute("aria-controls")}`)
            .classList.add("is-active");
    }
};

export default policyTabs;
