import { widgetPreviewUpdateEventHandler } from "./widgetPreviewUpdateEventHandler";

export default () => {
    const iframe = document.querySelector("#widget-preview-iframe");
    const iframeWindow = iframe && iframe.contentWindow;

    /*
     * Theme tab
     */
    const themeInputs = document.querySelectorAll(
        "[data-update-preview-theme]"
    );
    const appearanceInputs = document.querySelectorAll(
        "[data-update-preview-appearance]"
    );
    const positionsRadioGroup = document.getElementById(
        "widget-radio-group-embed_config[layout][position]"
    );
    const positionInputs = document.querySelectorAll(
        "[data-update-preview-position]"
    );

    /*
     * Configuration Tab
     */
    const preferencesInputs = document.querySelectorAll(
        "[data-update-preview-preferences]"
    );
    const blockInteractionInputs = document.querySelectorAll(
        "[data-update-preview-block-interaction]"
    );
    const rejectInputs = document.querySelectorAll(
        "[data-update-preview-reject-all]"
    );
    const updatePreferenceInputs = document.querySelectorAll(
        "[data-update-preview-update-after-close]"
    );
    /*
     * Content Tab
     */
    const cookiePolicyLanguageDropdown = document.querySelector(
        "[data-update-preview-language]"
    );

    /**
     * We have multiples of all content fields because we have multiple languages.
     * There are enough of these fields to where it is now useful to keep track of all of the identifying data attributes for each set.
     *
     * Here we map the data attributes to the corresponding preview update event, used in widgetPreviewUpdateEventHandler.
     */
    const widgetContentFieldMap = {
        "[data-update-preview-policy]": "updateCookiePolicyUrl",
        "[data-update-preview-link-text]": "updateCookiePolicyLinkText",
        "[data-update-dialog-title]": "updateDialogTitle",
        "[data-update-dialog-message]": "updateDialogMessage",
        "[data-update-dialog-save-preferences-button]":
            "updateDialogSavePreferences",
        "[data-update-dialog-accept-all-button]": "updateDialogAcceptAll",
        "[data-update-dialog-reject-all-button]": "updateDialogRejectAll",
        "[data-update-preview-message]": "updateMessage",
        "[data-update-widget-pill-message]": "updatePillMessage",
        "[data-update-widget-manage-preferences-button]":
            "updateWidgetManagePreferences",
        "[data-update-widget-reject-all-button]": "updateWidgetRejectAllButton",
        "[data-update-widget-accept-all-button]": "updateWidgetAcceptAllButton",
        "[data-update-preview-update-after-close]": "updateShowPreferencesAfterClose",
        "[data-update-widget-show-cookies-button]":
            "updateWidgetShowCookiesButton",
        "[data-update-widget-hide-cookies-button]":
            "updateWidgetHideCookiesButton",
        "[data-update-cookie-essential-title]": "updateEssentialTitle",
        "[data-update-cookie-essential-description]":
            "updateEssentialDescription",
        "[data-update-cookie-functional-title]": "updateFunctionalTitle",
        "[data-update-cookie-functional-description]":
            "updateFunctionalDescription",
        "[data-update-cookie-marketing-title]": "updateMarketingTitle",
        "[data-update-cookie-marketing-description]":
            "updateMarketingDescription",
        "[data-update-cookie-analytics-title]": "updateAnalyticsTitle",
        "[data-update-cookie-analytics-description]":
            "updateAnalyticsDescription",
        "[data-update-cookie-unclassified-title]": "updateUnclassifiedTitle",
        "[data-update-cookie-unclassified-description]":
            "updateUnclassifiedDescription",
        "[data-update-cookie-heading-platform]": "updateCookieHeadingPlatform",
        "[data-update-cookie-heading-name-description]":
            "updateCookieHeadingNameDescription",
        "[data-update-cookie-heading-retention]":
            "updateCookieHeadingRetention",
    };

    const widgetFullPreviewButton = document.querySelector(
        "#widget-preview-full"
    );

    let isWidgetPreviewShowing = false;

    document.addEventListener("previewUpdate", (event) => {
        const eventData = event.detail.data;
        const reinit = event.detail.reinit;
        // Update in-page preview
        widgetPreviewUpdateEventHandler(
            eventData,
            reinit && isWidgetPreviewShowing
        );

        // Update iframe preview
        iframeWindow.postMessage({ data: eventData, reinit });
    });

    function triggerPreviewUpdate(data, reinit = true) {
        document.dispatchEvent(
            new CustomEvent("previewUpdate", { detail: { data, reinit } })
        );
    }

    if (iframeWindow) {
        window.gtCookieWidgetConfig = JSON.parse(iframe.dataset.config);

        /*
         * Theme Tab
         */
        themeInputs.forEach((input) =>
            input.addEventListener("input", () => {
                triggerPreviewUpdate(
                    {
                        type: "updateTheme",
                        value: {
                            variable: input.dataset.updatePreviewTheme,
                            value: input.value,
                        },
                    },
                    true
                );
            })
        );
        appearanceInputs.forEach((input) =>
            input.addEventListener("change", () => {
                if (input.checked) {
                    triggerPreviewUpdate(
                        {
                            type: "updateAppearance",
                            value: input.value,
                        },
                        true
                    );

                    positionsRadioGroup.classList.toggle(
                        "hide",
                        input.value === "banner" || input.value === "popup"
                    );
                }
            })
        );
        positionInputs.forEach((input) =>
            input.addEventListener("change", () => {
                if (input.checked) {
                    triggerPreviewUpdate(
                        {
                            type: "updatePosition",
                            value: input.value,
                        },
                        true
                    );
                }
            })
        );

        /*
         * Configuration Tab
         */
        preferencesInputs.forEach((input) =>
            input.addEventListener("change", () => {
                if (input.checked) {
                    triggerPreviewUpdate(
                        {
                            type: "updateAllowPreferences",
                            value: input.value,
                        },
                        true
                    );
                }
            })
        );
        blockInteractionInputs.forEach((input) =>
            input.addEventListener("change", () => {
                if (input.checked) {
                    triggerPreviewUpdate(
                        {
                            type: "updateBlockInteraction",
                            value: input.value,
                        },
                        true
                    );
                }
            })
        );
        rejectInputs.forEach((input) =>
            input.addEventListener("change", () => {
                if (input.checked) {
                    triggerPreviewUpdate(
                        {
                            type: "updateRejectAll",
                            value: input.value,
                        },
                        true
                    );
                }
            })
        );
        updatePreferenceInputs.forEach((input) =>
            input.addEventListener("change", () => {
                if (input.checked) {
                    triggerPreviewUpdate(
                        {
                            type: "updateShowPreferencesAfterClose",
                            value: input.value,
                        },
                        true
                    );
                }
            })
        );



        /*
         * Content Tab
         */

        if (cookiePolicyLanguageDropdown) {
            cookiePolicyLanguageDropdown.addEventListener("change", () => {
                const languageContainer = document.querySelector(
                    `[data-content-id="${cookiePolicyLanguageDropdown.value}"`
                );

                Object.entries(widgetContentFieldMap).forEach(
                    ([selector, event], index, array) => {
                        const input = languageContainer.querySelector(selector);
                        triggerPreviewUpdate(
                            {
                                type: event,
                                value: input.value,
                            },
                            index === array.length - 1
                        );
                    }
                );
            });
        }

        Object.entries(widgetContentFieldMap).forEach(([selector, event]) => {
            const inputs = document.querySelectorAll(selector);
            inputs.forEach((input) => {
                input.addEventListener("change", (e) => {
                    triggerPreviewUpdate({
                        type: event,
                        value: input.value,
                    });
                });
            });
        });
    }

    if (widgetFullPreviewButton) {
        widgetFullPreviewButton.addEventListener("click", () => {
            const scriptTag = document.querySelector("#widget-script");
            const textTag = widgetFullPreviewButton.querySelector(".inner");

            isWidgetPreviewShowing = !isWidgetPreviewShowing;
            updateButton();

            if (isWidgetPreviewShowing) {
                if (!scriptTag) {
                    const script = document.createElement("script");
                    script.src = "/build/js/cookie-widget-vue.js";
                    script.id = "widget-script";
                    script.async = false;
                    script.onload = () => {
                        window.gtCookieWidgetPreview();
                        document.addEventListener(
                            "click",
                            onDocumentClickCheckWidgetDismiss
                        );
                    };
                    document.head.appendChild(script);
                } else {
                    window.gtCookieWidgetPreview();
                    document.addEventListener(
                        "click",
                        onDocumentClickCheckWidgetDismiss
                    );
                }
            } else {
                const widget = document.querySelector(
                    ".gt-cookie-widget-wrapper"
                );
                widget && widget.remove();
                document.removeEventListener(
                    "click",
                    onDocumentClickCheckWidgetDismiss
                );
            }

            function updateButton() {
                widgetFullPreviewButton.classList.toggle(
                    "btn--border-primary",
                    isWidgetPreviewShowing
                );
                widgetFullPreviewButton.classList.toggle(
                    "btn--border-grey",
                    !isWidgetPreviewShowing
                );
                textTag.textContent = isWidgetPreviewShowing
                    ? widgetFullPreviewButton.dataset.hideMessage
                    : widgetFullPreviewButton.dataset.showMessage;
            }

            function onDocumentClickCheckWidgetDismiss(e) {
                if (
                    e.target.closest(
                        "[data-gt-cookie-accept-all], [data-gt-cookie-save], [data-gt-cookie-reject-all]"
                    )
                ) {
                    isWidgetPreviewShowing = false;
                    updateButton();
                    document.removeEventListener(
                        "click",
                        onDocumentClickCheckWidgetDismiss
                    );
                }
            }
        });
    }
};
