function comparisonToggle() {
    const optionsSection = document.querySelector(".hero-home");
    if (optionsSection) {
        const compareBtn = optionsSection.querySelector(".js-compare");
        const inclusionsLists = Array.prototype.slice.call(
            optionsSection.querySelectorAll(".js-inclusions")
        );

        const compareIsOpen = () =>
            optionsSection.classList.contains("js-compare-open");

        // Remove inline styles after the transition
        inclusionsLists.forEach(item => {
            item.addEventListener("transitionend", () => {
                item.style = "";
            });
        });

        const openCompare = () => {
            inclusionsLists.forEach(item => {
                item.style.maxHeight = "0px";
                item.style.overflow = "hidden";
                void item.offsetHeight;
                item.style.transition =
                    "max-height 0.5s cubic-bezier(0.2, 0, 0, 1)";
                item.style.maxHeight = item.scrollHeight + "px";
                item.setAttribute("aria-expanded", true);
            });
            optionsSection.classList.add("js-compare-open");
        };

        const closeCompare = () => {
            inclusionsLists.forEach(item => {
                item.style.maxHeight = item.scrollHeight + "px";
                item.style.transition =
                    "max-height 0.5s cubic-bezier(0.2, 0, 0, 1)";
                item.style.overflow = "hidden";
                void item.offsetHeight;
                item.style.maxHeight = "0px";
                item.setAttribute("aria-expanded", false);
            });
            optionsSection.classList.remove("js-compare-open");
        };

        compareBtn.addEventListener("click", e => {
            const span = e.currentTarget.querySelector("span.inner");
            let isOpening = true;
            if (compareIsOpen()) {
                isOpening = false;
                closeCompare();
            } else {
                openCompare();
            }

            if (span) {
                span.innerText = isOpening
                    ? "Hide Comparison"
                    : "Compare Packages";
            }
        });
    }
}

export default comparisonToggle;
