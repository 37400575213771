function menuToggle() {
    const mainNav = document.querySelector(".main-nav");
    const hamburgerSelector = document.querySelectorAll(".js-open-main-nav");

    // Click event for the hamburger
    // ------------------------------------------------
    hamburgerSelector.forEach(function(hamburger) {
        hamburger.addEventListener("click", function(e) {
            e.preventDefault();

            if (!document.documentElement.classList.contains("is-menu-open")) {
                mainNav.removeAttribute("hidden");

                setTimeout(function() {
                    // disable the scroll
                    scrollControl.disable();
                    document.documentElement.classList.add("is-menu-open");
                    mainNav.querySelector(".title").focus();
                }, 100);
            } else {
                // is locked, enable the scroll again
                scrollControl.enable();
                document.documentElement.classList.remove("is-menu-open");

                setTimeout(function() {
                    mainNav.setAttribute("hidden", "");
                    document.querySelector(".hamburger").focus();
                }, 650);
            }
        });
    });

    // Esc key bindings
    document.addEventListener("keydown", menuEsc, false);
    function menuEsc(evt) {
        evt = evt || window.event;
        if (
            evt.keyCode == 27 &&
            document.documentElement.classList.contains("is-menu-open")
        ) {
            scrollControl.enable();
            document.documentElement.classList.remove("is-menu-open");

            setTimeout(function() {
                mainNav.setAttribute("hidden", "");
                document.querySelector(".hamburger").focus();
            }, 650);
        }
    }

    // Lock page scroll behind
    // ------------------------------------------------
    const scrollControl = {
        scrollContainer: document.scrollingElement || document.documentElement,
        scrollTop: null,
        originalStyles: null,

        enable: () => {
            document.body.style.cssText = scrollControl.originalStyles;
            scrollControl.scrollContainer.scrollTop = scrollControl.scrollTop;
        },

        disable: () => {
            scrollControl.scrollTop = scrollControl.scrollContainer.scrollTop;
            scrollControl.originalStyles = document.body.style.cssText;
            document.body.style.cssText = `overflow: hidden; position: fixed; height: 100%; width: 100%; top: ${-scrollControl.scrollTop}px`;
        },
    };
}

export default menuToggle;
