/*global $, completed_order, dataLayer */
import trackEvent from "../components/trackEvent.js";

const initThankYou = () => {
    if (!$("body").hasClass("thank-you")) {
        return;
    }

    if (typeof completed_order !== "undefined") {
        trackEvent({
            category: completed_order.type,
            action: "payment",
            label: completed_order.token,
            value: completed_order.price,
        });

        if (typeof dataLayer != "undefined") {
            // create dataLayer when user made a successful payment (ecommerce.purchase)
            // (Send transaction data with a pageview if available when the page loads. Otherwise, use an event when the transaction data becomes available.)
            dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
            dataLayer.push({
                ecommerce: {
                    purchase: {
                        actionField: {
                            id: completed_order.token,
                            affiliation: "Online Store",
                            revenue: completed_order.price,
                            tax: "0",
                            shipping: "0",
                            coupon: completed_order.coupon,
                        },
                        products: [
                            {
                                name: completed_order.type,
                                price: completed_order.price,
                                brand: "GetTerms",
                                category: "eCommerce",
                                quantity: 1,
                            },
                        ],
                    },
                },
            });
        }

        setTimeout(function() {
            $(".thank-you--message").hide();
            $(".thank-you--proceed").show();

            window.location.href = completed_order.url;
        }, 5000);
    }
};

export default initThankYou;
