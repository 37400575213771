/* global jQuery */

function typeDropdown() {
    const typeDropdownEl = document.querySelector(".type-dropdown");

    if (typeDropdownEl) {
        const button = typeDropdownEl.querySelector("button");
        button.addEventListener("click", () => {
            let is_expanded = typeDropdownEl.classList.contains("is-open")
                ? true
                : false;

            if (!is_expanded) {
                // Remove attribute hidden
                typeDropdownEl
                    .querySelector(".types-wrap")
                    .removeAttribute("hidden");

                // Wait a split second and then add class - animating in the menu
                setTimeout(function() {
                    typeDropdownEl.classList.add("is-open");
                }, 200);
            } else {
                // Remove the class, then wait .2s and add the hidden back into the dom
                typeDropdownEl.classList.remove("is-open");
                typeDropdownEl.querySelector("button").focus();

                // Wait a split second and then add class - animating in the menu
                setTimeout(function() {
                    typeDropdownEl
                        .querySelector(".types-wrap")
                        .setAttribute("hidden", "");
                }, 200);
            }

            toggleDropdown(button, is_expanded ? false : true);
        });
    }

    // Esc key bindings
    document.addEventListener("keydown", typeEsc, false);

    function typeEsc(evt) {
        evt = evt || window.event;
        if (evt.keyCode == 27) {
            typeClose();
        }
    }

    document.addEventListener(
        "click",
        evt => {
            if (
                !evt.target.classList.contains("type-dropdown") &&
                jQuery(evt.target).parents(".type-dropdown").length === 0
            ) {
                typeClose();
            }
        },
        false
    );

    function typeClose() {
        if (typeDropdownEl == null) {
            return;
        }

        // Remove the class, then wait .2s and add the hidden back into the dom
        typeDropdownEl.classList.remove("is-open");
        //typeDropdownEl.querySelector("button").focus();

        // Wait a split second and then add class - animating in the menu
        setTimeout(function() {
            typeDropdownEl
                .querySelector(".types-wrap")
                .setAttribute("hidden", "");
        }, 200);
    }

    function toggleDropdown(toggle, is_expanded) {
        const target = document.getElementById(
            toggle.getAttribute("aria-controls")
        );
        toggle.setAttribute("aria-expanded", is_expanded ? "true" : "false");

        if (target) {
            target.setAttribute(
                "data-expanded",
                is_expanded ? "true" : "false"
            );
        }
    }
}

export default typeDropdown;
