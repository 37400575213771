/* global $ */

const agencyManagement = () => {
    // const revokeInviteUrl = document.getElementById("js-data").dataset
    //     .revokeInviteUrl;
    const deleteButtons = document.querySelectorAll(".delete-agency-user");
    const roleSelects = document.querySelectorAll(".agency-user-role");
    const inviteForm = document.getElementById("invite-agency-user");
    const csrfToken = $('meta[name="csrf-token"]').attr("content");
    const headers = {
        "X-CSRF-TOKEN": csrfToken,
    };
    const successWrapper = document.getElementById("agency-success-wrapper");
    const errorWrapper = document.getElementById("agency-error-wrapper");

    deleteButtons.forEach(button => {
        button.addEventListener("click", function(event) {
            const userId = this.getAttribute("data-user");

            $(successWrapper).hide();
            $(errorWrapper).hide();

            $.ajax({
                type: "POST",
                url: "/manage-users/remove-user",
                data: {
                    userId: userId,
                },
                headers: headers,
            })
                .done(function(data) {
                    $('[data-userid="' + data.userId + '"]').remove();
                    $(successWrapper)
                        .html(data.message)
                        .show();
                    setTimeout(function() {
                        $(successWrapper).fadeOut("slow");
                    }, 5000);
                    if (data.total < 4) {
                        showInviteForm();
                    }
                })
                .fail(function(data) {
                    let errorMessage =
                        data.responseJSON.message || "An error occurred";
                    $(errorWrapper)
                        .html(errorMessage)
                        .show();
                });
        });
    });

    roleSelects.forEach(select => {
        let originalValue;

        select.addEventListener("focus", function() {
            originalValue = this.value;
        });

        select.addEventListener("change", function() {
            const userId = this.getAttribute("data-user");
            const selectedOptionValue = this.value;

            $(successWrapper).hide();
            $(errorWrapper).hide();

            $.ajax({
                type: "POST",
                url: "/manage-users/set-user-role",
                data: {
                    userId: userId,
                    role: selectedOptionValue,
                },
                headers: headers,
            })
                .done(function(response) {
                    $(successWrapper)
                        .html(response.message)
                        .show();
                    setTimeout(function() {
                        $(successWrapper).fadeOut("slow");
                    }, 5000);
                })
                .fail(function(response) {
                    let errorMessage =
                        response.responseJSON.message || "An error occurred";
                    $(errorWrapper)
                        .html(errorMessage)
                        .show();
                });
        });
    });

    if (inviteForm) {
        inviteForm.addEventListener("submit", function(e) {
            e.preventDefault();
            const formData = new FormData(this);

            $(successWrapper).hide();
            $(errorWrapper).hide();

            fetch("/manage-users/invite-user", {
                method: "POST",
                body: formData,
                headers: headers,
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        var newListItem = $(
                            "<div class='card-user'><svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'><path fill='#065af9' d='M13.43 10.51c-.3-.73-.74-1.39-1.3-1.95s-1.21-1-1.95-1.3c-.18-.07-.36-.14-.55-.2.98-.59 1.64-1.66 1.64-2.88C11.27 2.32 9.76.8 7.9.8S4.52 2.32 4.52 4.18c0 1.22.66 2.28 1.64 2.88-.94.28-1.79.79-2.5 1.5A6.03 6.03 0 0 0 1.9 12.8v1.5c0 .41.34.75.75.75h10.5c.41 0 .75-.34.75-.75v-1.5c-.01-.79-.16-1.56-.47-2.29zm-5.54-8.2c1.03 0 1.87.84 1.87 1.88s-.84 1.87-1.87 1.87-1.87-.84-1.87-1.88.84-1.87 1.87-1.87zm4.5 11.25h-9v-.75c0-1.18.48-2.34 1.32-3.18 1.27-1.27 3.24-1.66 4.9-.98.55.23 1.04.56 1.46.98s.75.91.97 1.46c.23.55.34 1.13.34 1.72v.75z'/></svg><span class='card-user__text'>" +
                                data.email +
                                '</span> <button type="button" class="card-user__button revoke-invite" data-invite="' +
                                data.id +
                                '">Revoke</button></div>'
                        );
                        $("#agency-invited-users").show();
                        $("#agency-invited-users-list").append(newListItem);
                        //clear inviteForm data
                        inviteForm.reset();
                        if (data.total >= 4) {
                            hideInviteForm();
                        }
                        $(successWrapper)
                            .html(data.message)
                            .show();
                        setTimeout(function() {
                            $(successWrapper).fadeOut("slow");
                        }, 5000);
                    }
                    if (data.errors) {
                        let errorMessageHtml = "";

                        for (const fieldName in data.errors) {
                            data.errors[fieldName].forEach(function(error) {
                                errorMessageHtml += error + "<br>";
                            });
                        }

                        $(errorWrapper)
                            .html(errorMessageHtml)
                            .show();
                    }
                })
                .catch(error => {
                    // console.error("Error:", error);
                    // Handle the error
                });
        });
    }

    $("#invite-agency-user").on("click", ".revoke-invite", function() {
        const inviteId = $(this).data("invite");

        let formData = new FormData();
        formData.append("invite", inviteId);

        // Convert FormData to a plain object
        const formDataObj = Object.fromEntries(formData.entries());

        $(successWrapper).hide();
        $(errorWrapper).hide();

        $.ajax({
            type: "POST",
            url: "/manage-users/revoke-invite",
            data: formDataObj,
            headers: headers,
            success: function(data) {
                if (data.success) {
                    $('[data-invite="' + data.inviteId + '"]')
                        .closest(".card-user")
                        .remove();
                    $(successWrapper)
                        .html(data.message)
                        .show();
                    setTimeout(function() {
                        $(successWrapper).fadeOut("slow");
                    }, 5000);
                    if (data.total < 4) {
                        showInviteForm();
                    }
                    if (data.invited === 0) {
                        $("#agency-invited-users").hide();
                    } else {
                        $("#agency-invited-users").show();
                    }
                }
                if (data.errors) {
                    let errorMessageHtml = "";
                    for (const fieldName in data.errors) {
                        data.errors[fieldName].forEach(function(error) {
                            errorMessageHtml += error + "<br>";
                        });
                    }
                    $(errorWrapper)
                        .html(errorMessageHtml)
                        .show();
                }
            },
            error: function(jqXHR) {
                let errorMessage = "An error occurred";
                if (jqXHR.responseJSON && jqXHR.responseJSON.message) {
                    errorMessage = jqXHR.responseJSON.message;
                }
                $(errorWrapper)
                    .html(errorMessage)
                    .show();
            },
        });
    });
};

function hideInviteForm() {
    const elements = document.querySelectorAll(
        '[data-form-component="invite-form"]'
    );
    elements.forEach(element => {
        element.style.display = "none";
    });
}

function showInviteForm() {
    const elements = document.querySelectorAll(
        '[data-form-component="invite-form"]'
    );
    elements.forEach(element => {
        element.style.display = ""; // can we just remove the inline style?
    });
}

export default agencyManagement;
