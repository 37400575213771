/*global dataLayer*/

const trackEvent = function(e) {
    //Track ajax event (category, action, label, value) via dataLayer & custom-event proxying within GTM
    var category = e["category"];
    var action = e["action"];
    var label = e["label"];
    var value = e["value"];

    if (typeof dataLayer != "undefined") {
        dataLayer.push({
            event: "GACustomEvent",
            event_category: category,
            event_action: action,
            event_label: label,
            event_value: value,
        });
    }
};

export default trackEvent;
