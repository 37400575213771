/* global jQuery */

class paymentPlanDropdownHandler {
    constructor(
        selector,
        config = {
            inputName: "subscription_type",
            classname: "payment-plan-trigger",
            itemsClassName: "payment-plan-item",
            dropdownClassName: "payment-plan-dropdown",
            events: {},
        }
    ) {
        this.selector = selector;
        this.config = config;
        this.elements = document.querySelectorAll(this.selector);
        this.is_ie11 = !!window.MSInputMethodContext && !!document.documentMode;

        if (this.elements.length === 0) {
            return;
        }

        this.mount();
    }

    mount() {
        if (this.config.events) {
            Object.keys(this.config.events).forEach(eventName => {
                let eventCallback = this.config.events[eventName];

                this.elements.forEach(
                    el => el.addEventListener(eventName, eventCallback),
                    {
                        passive: true,
                        once: true,
                    }
                );
            });
        }

        this.init();
    }

    init() {
        this.elements.forEach(trigger => {
            trigger.addEventListener("click", () => {
                this.toggleClick(
                    trigger,
                    trigger.getAttribute("aria-expanded") == "true"
                        ? false
                        : true
                );
            });

            document.addEventListener(
                "click",
                evt => {
                    if (
                        !evt.target.classList.contains(this.config.classname) &&
                        jQuery(evt.target).parents(`.${this.config.classname}`)
                            .length === 0 &&
                        jQuery(evt.target).parents(
                            `.${this.config.dropdownClassName}`
                        ).length === 0
                    ) {
                        this.toggleClick(trigger, false);
                    }
                },
                false
            );
        });

        this.planChange(
            document.querySelectorAll(
                'input[type="radio"][name="' + this.config.inputName + '"]'
            )
        );

        const wrapper = document.querySelector(".payment-plan");
        if (!wrapper.classList.contains("payment-plan--initialized")) {
            wrapper.classList.add("payment-plan--initialized");
        }

        // we need to set the initial price here.
        if (
            document.querySelectorAll(
                'input[type="radio"][name="' +
                    this.config.inputName +
                    '"]:checked'
            ).length === 0
        ) {
            document.querySelector(
                'input[type="radio"][name="' + this.config.inputName + '"]'
            ).checked = true;
        }
    }

    toggleClick(trigger, is_expanded) {
        let target = document.getElementById(
            trigger.getAttribute("aria-controls")
        );

        trigger.setAttribute("aria-expanded", is_expanded);

        if (target) {
            target.setAttribute("data-expanded", is_expanded);
        }
    }

    planChange(radios) {
        Object.values(radios).forEach(radio => {
            radio.addEventListener("change", () => {
                let trigger = document.querySelector(
                    '.payment-plan-trigger[aria-controls="' +
                        radio.parentNode.parentNode.parentNode.getAttribute(
                            "id"
                        ) +
                        '"]'
                );

                this.toggleClick(trigger, false);

                this.updateTriggerLabel(
                    trigger,
                    document.querySelector(
                        'label[for="' + radio.getAttribute("id") + '"'
                    ).innerHTML
                );

                this.updateTotal(radio.dataset.frequency, radio.dataset.price);
            });
        });

        if (
            document.querySelectorAll(
                'input[type="radio"][name="' +
                    this.config.inputName +
                    '"]:checked'
            ).length > 0
        ) {
            document
                .querySelectorAll(
                    'input[type="radio"][name="' +
                        this.config.inputName +
                        '"]:checked'
                )[0]
                .dispatchEvent(new Event("change", { bubbles: true }));
        }
    }

    updateTriggerLabel(trigger, value) {
        trigger.querySelector(".btn__label").innerHTML = value;
    }

    updateTotal(type, cost) {
        let sum;
        let frequency;

        switch (type) {
            case "once":
                sum = cost;
                frequency = "";

                break;
            case "year":
                sum = cost;
                frequency = "/year";
                break;
            case "month":
                sum = cost;
                frequency = "/month";
                break;
            default:
        }

        document.querySelector("span[data-sum]").innerHTML = sum;
        document.querySelector("span[data-frequency]").innerHTML = frequency;
    }
}

export default paymentPlanDropdownHandler;
