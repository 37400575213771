function setVariableHeight(
    el,
    variable = "--height",
    parent = document.documentElement
) {
    if (el == parent) {
        el.style.maxHeight = "none";
    }

    let el_height = el.offsetHeight;
    parent.style.setProperty(variable, el_height + "px");

    if (el == parent) {
        el.style.maxHeight = null;
    }

    let variableTimeout;

    window.addEventListener("resize", function() {
        clearTimeout(variableTimeout);
        variableTimeout = setTimeout(function() {
            if (el == parent) {
                el.style.maxHeight = "none";
            }
            var el_height = el.offsetHeight;
            parent.style.setProperty(variable, el_height + "px");

            if (el == parent) {
                el.style.maxHeight = null;
            }
        }, 150);
    });
}

export default setVariableHeight;
