export default function widgetLanaguage() {
    const languageDropdown = document.getElementById("languageSelect");

    if (languageDropdown) {
        languageDropdown.addEventListener("change", () => {// Hide all elements with the class 'widget-content-language'
            document
                .querySelectorAll(".widget-content-language")
                .forEach(element => {
                    element.style.display = "none";
                    element.removeAttribute("data-active-language");
                });

            // Show the element that matches 'data-content-id' with the selected value
            const contentToShow = document.querySelector(
                `[data-content-id="${languageDropdown.value}"]`
            );

            if (contentToShow) {
                contentToShow.style.display = "block"; // or you can use 'flex', 'grid' etc. depending on your layout needs
                contentToShow.dataset.activeLanguage = "true";
            }
        });
    }
}
