/*global $*/
const reviews = () => {
    $(function() {
        //Stop it, get out now, quick.
        if ($("div.widget-reviews").length == 0) {
            return;
        }

        $(".widget-reviews input, .widget-reviews textarea")
            .each(function() {
                input_fill($(this));
            })
            .on("focus blur click", function() {
                input_fill($(this));
            });

        function input_fill(self) {
            if (self.val().length > 0) {
                self.parent().addClass("is-filled");
            }
        }

        // Rating
        if ($(".widget-reviews__rating").length) {
            $(".widget-reviews__rating-item")
                .on("mouseover", function() {
                    $(this)
                        .prevAll(".widget-reviews__rating-item")
                        .addClass("widget-reviews__rating-item--active");
                    $(this)
                        .nextAll(".widget-reviews__rating-item")
                        .addClass("widget-reviews__rating-item--hover");
                })
                .on("mouseout", function() {
                    $(".widget-reviews__rating-item").removeClass(
                        "widget-reviews__rating-item--active widget-reviews__rating-item--hover"
                    );
                });
        }

        $("body").on("click", ".widget-reviews__close", function() {
            $(".widget-reviews").addClass("widget-reviews--hide");

            setTimeout(function() {
                $(".widget-reviews").remove();
            }, 1000);
        });

        //Form processing
        $('input[name="sentiment"]').change(function() {
            var sentiment = $('input[name="sentiment"]:checked').val();
            $(".reviews-when-" + sentiment).show();
            $(".widget-reviews__step").hide();
            $(".widget-reviews__step--2").show();
        });

        $("#review-form").validate({
            rules: {},
            submitHandler: function(form) {
                var $form = $(form);
                var data = $form.serialize();
                var $button = $form.find('button[type="submit"]');

                $button.text("Submitting...").prop("disabled", true);

                $.ajax({
                    type: "POST",
                    url: "/api/review",
                    data: data,
                    dataType: "json",
                })
                    .done(function(data) {
                        $button.prop("disabled", false).text("Submit feedback");

                        if (!data.error) {
                            $(".widget-reviews__step").hide();
                            $(".widget-reviews__step--3").show();
                            setTimeout(function() {
                                $(".widget-reviews").addClass(
                                    "widget-reviews--hide"
                                );
                            }, 5000);
                        }
                    })
                    .fail(function() {
                        $button.prop("disabled", false).text("Try again");
                    });
            },
        });
    });
};

export default reviews;
