import "focus-visible";
import "lazysizes";

import jQuery from "jquery";

import $ from 'jquery';
window.$ = window.jQuery = $;

import smoothScroll from "./components/smoothScroll.js";
import accordion from "./components/accordion.js";
import accordionCookies from "./components/accordionCookies.js";
import colorPicker from "./components/colorPicker.js";
import widgetLanguage from "./components/widgetLanguage.js";
import widgetMessage from "./components/widgetMessage.js";
import widgetPreview from "./components/widgetPreview.js";
import widgetTabs from "./components/widgetTabs.js";
import widgetInstructions from "./components/widgetInstructions.js";
import copyText from "./components/copyText.js";
import typeDropdown from "./components/typeDropdown.js";
import menuToggle from "./components/menuToggle.js";
import planSelect from "./components/planSelect.js";
import comparisonToggle from "./components/comparisonToggle.js";
import reviewsTicker from "./components/reviewsTicker.js";
import addLoader from "./components/addLoader.js";

window.addLoader = addLoader;
import flatpickr from "flatpickr";
import reviews from "./src/reviews.js";
//import policyForm from "./src/policy-form.js";
//import policyFormUpdate from "./src/policy-form-update.js";
import agencyManagement from "./src/agency-management";
import policyTabs from "./src/policy-tabs.js";
import policyUpdate from "./src/policy-update.js";
import policyView from "./src/policy-view.js";
// import policySample from "./src/policy-sample.js";
import initThankYou from "./src/thank-you.js";
// import policyFormFields from "./src/policy-form-fields.js";
import purchaseForm from "./src/purchase-form.js";
import enableSubmit from "./src/enable-submit.js";
import setVariableHeight from "./components/setVariableHeight.js";
import BtnExpand from "./components/BtnExpand.js";
import paymentPlanDropdownHandler from "./components/paymentPlanDropdownHandler.js";
import PaymentPlanToggle from "./classes/PaymentPlanToggle.js";

// Custom select
// import policyCustomSelect from "./src/policy-custom-select.js";
import customSelect from "custom-select";

import modaal from "./resources/modaal.importable-customised";
import formDefault from "./components/formDefault";

// Import static assets
import.meta.glob([
    '../js/**',
    '../css/**',
    '../img/**',
    '../fonts/**',
]);

window.modaal = modaal;

window.addEventListener("load", function() {
    colorPicker();
    widgetLanguage();
    widgetPreview();
    widgetTabs();
    widgetMessage();
    widgetInstructions();
    copyText();
    formDefault();
    // purchaseForm();
    typeDropdown();

    // Cookie delete confirmation
    const deleteForm = document.querySelector("#delete-form");
    if (deleteForm) {
        deleteForm.addEventListener('submit', function (event) {
            if (!confirm('Are you sure?')) {
                event.preventDefault();
            }
        });
    }

    customSelect(".custom-select");

    const signin = document.body;
    if (signin.classList.contains("signin")) {
        const firstInput = signin.querySelector(".fields input");
        firstInput && firstInput.focus();
    }

    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    var vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", vh + "px");

    // We listen to the resize event
    window.addEventListener("resize", function() {
        // We execute the same script as before
        var vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", vh + "px");
    });

    setVariableHeight(
        document.getElementsByClassName("header-main")[0],
        "--header-height"
    );

    new BtnExpand(".btn--expand", {
        classname: "btn--expand",
        closeOnOutsideClick: true,
        event: {},
    });

    smoothScroll();
    accordion();
    accordionCookies();
    menuToggle();
    planSelect();
    comparisonToggle();
    reviews();
    reviewsTicker();
    // policyForm();
    //policyFormUpdate();
    policyUpdate();
    agencyManagement();

    modaalInit();

    // Policy Form Field/Widgets initialization.
    // policyFormFields();
    // policyCustomSelect();
    purchaseForm();

    enableSubmit();

    // policySample();

    policyTabs();

    policyView();

    initThankYou();

    flatpickr(".date-picker", {
        altInput: true,
        altFormat: "j F Y",
        dateFormat: "j F Y",
    });

    new paymentPlanDropdownHandler(".payment-plan-trigger", {
        inputName: "subscription_type",
        classname: "payment-plan-trigger",
        itemsClassName: "payment-plan-item",
        dropdownClassName: "payment-plan-dropdown",
        events: {},
    });

    new PaymentPlanToggle(
        "input[type='radio'][name='subscription_frequency']",
        {}
    );

    // ----------------------------------------
    // Toggle the box open / closed depending on if the input has been checked
    // ----------------------------------------
    const ele = document.querySelectorAll("input.toggle");
    if (ele) {
        ele.forEach(function(item) {
            item.addEventListener("change", function() {
                if (item.checked) {
                    item.parentNode.classList.add("is-active");
                } else {
                    item.parentNode.classList.remove("is-active");
                }
            });
        });

        document.querySelectorAll("input.toggle:checked").forEach(item => {
            item.dispatchEvent(new Event("change"));
        });
    }

    // ----------------------------------------
    // Disable form submit button on submit to prevent double submission
    // ----------------------------------------
    const loginForm = document.getElementById("login-form");
    if (loginForm) {
        loginForm.addEventListener("submit", function() {
            document.getElementById("login-submit").disabled = true;
        });
    }

    // ----------------------------------------
    // Toggle viewing the policy option
    // ----------------------------------------
    const incomplete = document.getElementById("field_incomplete");

    if (incomplete) {
        incomplete.addEventListener("change", function() {
            if (incomplete.checked) {
                document.getElementById("purchase-form--options").submit();
            }
        });

        document
            .getElementById("field_incomplete")
            .dispatchEvent(new Event("change"));
    }

    // ----------------------------------------
    // Toggle the tabs mobile dropdown open and closed
    // ----------------------------------------
    const tabs_selector = document.querySelectorAll(".tabs__trigger");
    if (tabs_selector) {
        tabs_selector.forEach(function(item) {
            if (item.dataset.tab) {
                item.addEventListener("click", function(event) {
                    if (item.classList.contains("is-active")) {
                        if (!item.parentNode.classList.contains("is-open")) {
                            item.parentNode.classList.add("is-open");
                        } else {
                            item.parentNode.classList.remove("is-open");
                        }
                    }
                    event.preventDefault();
                });
            }
        });
    }
});

// ----------------------------------------
// Modaals
// ----------------------------------------
function modaalInit() {

    modaal(jQuery);

    jQuery(".modaal-preview").modaal({
        content_source: "#modaal-preview",
        custom_class: "container-preview",
    });
}

// close modaal
if (document.querySelector(".close-modaal")) {
    let close = document.querySelectorAll(".close-modaal");

    for (var i = 0; i < close.length; i++) {
        close[i].addEventListener("click", function() {
            // if a modaal is already open, close it
            if (document.getElementById("modaal-close")) {
                document.getElementById("modaal-close").click();
            }
        });
    }
}
