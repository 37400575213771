/**
 * Additional customizations for updating purchased policies within account area.
 */

/*global $, addLoader */
const policyUpdate = () => {
    // Update Form.
    $("#policy-update").on("submit", function(e) {
        const customTriggers = document.querySelectorAll(
            ".toggle--custom-trigger"
        );
        customTriggers.forEach(function(customTrigger) {
            if (!customTrigger.checked) {
                var customFor = customTrigger.dataset.customFor;
                var targetDiv = $('[data-custom-target="' + customFor + '"]');
                targetDiv.find(".add-your-own__list li").remove();
            }
        });
        const $form = $(this);
        const button = $form.get(0).querySelector('button[type="submit"]');
        const loader = addLoader();
        button.classList.add("btn--is-loading");
        button.prepend(loader);
        button.setAttribute("disabled", "disabled");
    });
};

export default policyUpdate;
