function accordionCookies() {
    // Convert node list to an array to save having to use an additional transform plugin for babel
    const theAccordionCookies = Array.prototype.slice.call(
        document.querySelectorAll(".accordion-cookies")
    );

    if (theAccordionCookies.length) {
        theAccordionCookies.forEach(accordionCookies => {
            const head = accordionCookies.querySelector(
                ".accordion-cookies__head"
            );
            const panel = accordionCookies.querySelector(
                ".accordion-cookies__panel"
            );
            const pinner = accordionCookies.querySelector(
                ".accordion-cookies__panel__inner"
            );

            const accordionCookiesIsActive = () =>
                accordionCookies.classList.contains("is-active");

            // remove inline styles after the transition
            pinner.setAttribute("hidden", "hidden");
            panel.addEventListener("transitionend", () => (panel.style = ""));

            const openPanel = () => {
                panel.style.maxHeight = "0px";
                panel.style.overflow = "hidden";
                void panel.offsetHeight;
                panel.style.transition =
                    "max-height 0.5s cubic-bezier(0.2, 0, 0, 1)";
                panel.style.maxHeight = panel.scrollHeight + "px";
                accordionCookies.classList.add("is-active");
                head.setAttribute("aria-expanded", true);
            };

            const closePanel = () => {
                panel.style.maxHeight = panel.scrollHeight + "px";
                panel.style.transition =
                    "max-height 0.5s cubic-bezier(0.2, 0, 0, 1)";
                panel.style.overflow = "hidden";
                void panel.offsetHeight;
                panel.style.maxHeight = "0px";
                accordionCookies.classList.remove("is-active");
                head.setAttribute("aria-expanded", false);
            };

            head.addEventListener("click", () => {
                if (!accordionCookiesIsActive()) {
                    pinner.removeAttribute("hidden");
                    openPanel();
                } else {
                    closePanel();
                    setTimeout(function() {
                        pinner.setAttribute("hidden", "hidden");
                    }, 500);
                }
            });
        });
    }
}

export default accordionCookies;
