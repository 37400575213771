import $ from 'jquery';

function smoothScroll() {
    // Select all links with hashes
    $('a[href*="#"]')
        // Remove links that don't actually link to anything
        .not('[href="#"]')
        .not('[href="#0"]')
        .click(function(event) {
            var scrollElem = "html, body";
            var innerScroll = false;
            var focusShift = true;
            var self = $(this);

            if (self.attr("data-scroll-element")) {
                innerScroll = true;
                scrollElem = self.attr("data-scroll-element");
            }
            if (self.attr("data-focus-shift") == "false") {
                focusShift = false;
            }

            // On-page links
            if (
                location.pathname.replace(/^\//, "") ==
                    this.pathname.replace(/^\//, "") &&
                location.hostname == this.hostname
            ) {
                // Figure out element to scroll to
                var target = $(this.hash);
                target = target.length
                    ? target
                    : $("[name=" + this.hash.slice(1) + "]");
                // Does a scroll target exist?
                if (target.length) {
                    // Only prevent default if animation is actually gonna happen
                    event.preventDefault();

                    var offset = target.offset().top;
                    if (innerScroll) {
                        offset = target.position().top;
                    }

                    var $scroll_animation_duration = window.matchMedia(
                        "(prefers-reduced-motion: reduce)"
                    ).matches
                        ? 0
                        : 1000;

                    $(scrollElem).animate(
                        {
                            scrollTop: offset - $(".header-main").outerHeight(),
                        },
                        $scroll_animation_duration,
                        function() {
                            if (focusShift) {
                                // Callback after animation
                                // Must change focus!
                                var $target = $(target);
                                $target.focus();
                                if ($target.is(":focus")) {
                                    // Checking if the target was focused
                                    return false;
                                } else {
                                    $target.attr("tabindex", "-1"); // Adding tabindex for elements not focusable
                                    var x = window.scrollX,
                                        y = window.scrollY;
                                    $target.focus();
                                    window.scrollTo(x, y);
                                    return $target;
                                }
                            }
                        }
                    );
                }
            }
        });
}

export default smoothScroll;
