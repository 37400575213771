function copyText() {
    const copyTextButtons = document.querySelectorAll("[data-copy]");

    copyTextButtons.forEach(button => {
        button.addEventListener("click", async () => {
            const copyTargetSelector = button.dataset.copy;
            const elementToCopy = document.querySelector(copyTargetSelector);

            if (elementToCopy) {
                try {
                    await navigator.clipboard
                        .writeText(elementToCopy.textContent.trim())
                        .then(() => {
                            let successMessage = document.querySelector(
                                ".cookie-tabs__panel--widget-instructions .clipboard-message"
                            );

                            // if no successMessage, get element id="widget-installation" and find the clipboard-message
                            if (!successMessage) {
                                const widgetInstallation = document.getElementById("widget-installation");
                                successMessage = widgetInstallation.querySelector(".clipboard-message");
                            }

                            successMessage.classList.add("is-active");
                            setTimeout(function() {
                                successMessage.classList.remove("is-active");
                            }, 1500);
                        });
                } catch (error) {
                    console.error(
                        `Failed to copy from selector ${copyTargetSelector}`,
                        error
                    );
                }
            } else {
                console.error(
                    `Failed to find element using selector ${copyTargetSelector}`
                );
            }
        });
    });
}

export default copyText;
