function colorPicker() {
    const fields = document.querySelectorAll(".widget-color");

    const hexRegex = /^#?[A-Fa-f0-9]{0,6}$/;

    fields.forEach(field => {
        const picker = field.querySelector(".widget-color__input");
        const text = field.querySelector(".widget-color__value");

        // We use this to keep a record of the text input's previous value in case of invalid input
        let textValue = text.value;

        // When color picker changes, auto-update the text field
        picker.addEventListener("input", () => {
            text.value = picker.value.toUpperCase();
        });

        // When text field changes, validate its input - if invalid, revert to previous value
        text.addEventListener("input", e => {
            if (!hexRegex.test(text.value)) text.value = textValue;

            textValue = text.value;
        });

        // Format and validate the text value, before commiting it to the color picker's value (which is used for updating the widget)
        text.addEventListener("change", () => {
            text.value = text.value.toUpperCase();

            // Add a leading hash if there isn't one already
            if (text.value[0] !== "#") {
                text.value = "#" + text.value;
            }

            // If the hex value isn't long enough, fill the remaining digits with zeroes
            const withoutHash = text.value.slice(1);
            if (withoutHash.length < 6) {
                const padZeroes = new Array(6 - withoutHash.length)
                    .fill("0")
                    .join("");
                text.value += padZeroes;
            }

            // Perform a final regex test before committing the value
            if (hexRegex.test(text.value)) {
                picker.value = text.value;
                picker.dispatchEvent(new Event("input"));
                textValue = text.value;
            }
        });
    });
}

export default colorPicker;
